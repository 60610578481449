import {
  AuditOutlined,
  HomeOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  QuestionCircleOutlined,
  QuestionCircleTwoTone,
  SettingOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { UserButton } from "@clerk/react-router";
import { getAuth } from "@clerk/react-router/ssr.server";
import { dark } from "@clerk/themes";
import { Button, Layout, Menu } from "antd";
import type { MenuItemType } from "antd/es/menu/interface";
import clsx from "clsx";
import { type ReactNode, useEffect, useState } from "react";
import type { MetaFunction } from "react-router";
import { Outlet, redirect, useLoaderData, useNavigate } from "react-router";
import logo from "~/assets/compact-logo.png";
import Feedback from "~/components/Feedback";
import { useDarkMode } from "~/hooks/useDarkMode";
import type { Route } from "./+types/AppLayout";

export const meta: MetaFunction = () => {
  return [
    { title: "SecondCrop by Loam" },
    { name: "description", content: "SecondCrop Farm Management Application" },
  ];
};

const navigation: {
  name: string;
  href?: string;
  icon: ReactNode;
  roles: string[];
  children?: { name: string; href: string }[];
}[] = [
  { name: "Home", href: "/", icon: <HomeOutlined />, roles: [] },
  {
    name: "Support",
    href: "/support",
    icon: <QuestionCircleOutlined />,
    roles: [],
  },
  {
    name: "Dealers",
    href: "/dealers",
    icon: <QuestionCircleTwoTone />,
    roles: ["dealer"],
  },
  {
    name: "Admin",
    icon: <ThunderboltOutlined />,
    roles: ["admin"],
    children: [
      { name: "Project Map", href: "/admin/project-map" },
      { name: "Projects", href: "/admin/projects" },
      { name: "Project Status", href: "/admin/project-status" },
      // Users are redirected to /onboarding when they don't have an organization
      // Admins can get to the onboarding page via the menu
      {
        name: "Onboarding",
        href: "/onboarding",
      },
    ],
  },
  {
    name: "System",
    icon: <SettingOutlined />,
    roles: ["admin"],
    children: [
      { name: "Jobs", href: "/admin/jobs" },
      { name: "Users", href: "/admin/users" },
    ],
  },
];

export const action = async (args: Route.ActionArgs) => {
  return null;
};

export const loader = async (args: Route.LoaderArgs) => {
  const { userId, sessionClaims } = await getAuth(args);
  if (!userId) {
    return redirect("/sign-in");
  }
  const loamRole = sessionClaims?.metadata?.loamRole;
  const commitHash = process.env.GIT_COMMIT_HASH;
  return { loamRole, commitHash };
};

export default function App() {
  const [collapsed, setCollapsed] = useState(false);
  const { loamRole, commitHash } = useLoaderData<typeof loader>();
  const navigate = useNavigate();

  const menuItems: MenuItemType[] = navigation
    .filter(
      (item) => item.roles.length === 0 || item.roles.includes(loamRole || ""),
    )
    .map((item) => ({
      key: item.name,
      label: item.name,
      icon: item.icon,
      onClick: () => (item.href ? navigate(item.href) : undefined),
      children: item.children?.map((child) => ({
        key: `${item.name}-${child.name}`,
        label: child.name,
        onClick: () => navigate(child.href),
      })),
    }));

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        setCollapsed(true);
      } else if (window.innerWidth >= 2000) {
        setCollapsed(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const darkMode = useDarkMode();

  return (
    <Layout hasSider>
      <Layout.Sider
        theme="light"
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          insetInlineStart: 0,
          top: 0,
          bottom: 0,
          scrollbarWidth: "thin",
          scrollbarColor: "unset",
        }}
      >
        <div className="flex flex-col justify-between h-screen">
          <div>
            <div className="flex justify-center space-x-5 w-full my-4">
              {!collapsed &&
                (darkMode ? (
                  <img className="h-8 w-auto" src={logo} alt="Loam Bio" />
                ) : (
                  <img className="h-8 w-auto" src={logo} alt="Loam Bio" />
                ))}
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
              />
            </div>
            <Menu mode="inline" defaultSelectedKeys={["1"]} items={menuItems} />
          </div>
          <div
            className={clsx(
              "flex p-5 transition-all",
              collapsed && "justify-center",
            )}
          >
            <UserButton
              appearance={{ baseTheme: darkMode ? dark : undefined }}
            />
          </div>
        </div>
      </Layout.Sider>
      <Layout
        style={{ marginInlineStart: collapsed ? 80 : 200 }}
        className="transition-all"
      >
        <Layout.Content>
          <main className="h-full min-h-screen">
            <Outlet />
          </main>
        </Layout.Content>
        <Layout.Footer>
          <p className="text-neutral-300 text-center">
            <a href="https://loambio.com" className="underline">
              Loam Bio
            </a>{" "}
            {new Date().getFullYear()} |{" "}
            <a href="https://secondcrop.com" className="underline">
              SecondCrop
            </a>{" "}
            | v: {commitHash?.slice(0, 7)}
          </p>
        </Layout.Footer>
      </Layout>
      <Feedback />
    </Layout>
  );
}
