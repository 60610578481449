import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  FloatButton,
  Form,
  type FormProps,
  Input,
  Modal,
  Typography,
} from "antd";
import { useState } from "react";

type FieldType = {
  name?: string;
  email?: string;
  feedback?: string;
};

export default function Feedback() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    fetch("/api/feedback", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    });
    setIsModalOpen(false);
    form.resetFields();
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo,
  ) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <FloatButton
        icon={<QuestionCircleOutlined />}
        tooltip="Report an issue / provide feedback"
        type="primary"
        style={{ insetInlineEnd: 24 }}
        onClick={showModal}
      />
      <Modal
        title="Report an issue / provide feedback"
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <Typography.Paragraph>
          Please let us know about any issues or suggestions you have.
        </Typography.Paragraph>
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please enter your name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item<FieldType>
            label="Email"
            name="email"
            rules={[
              { type: "email", message: "Please enter a valid email" },
              { required: true, message: "Please enter your email" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item<FieldType>
            label="Feedback"
            name="feedback"
            rules={[
              {
                required: true,
                message: "Don't forget to share your feedback!",
              },
            ]}
          >
            <Input.TextArea rows={3} />
          </Form.Item>
          <Form.Item label={null}>
            <Button type="primary" htmlType="submit">
              Send
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
